// src/components/Header.js

import React from 'react';
import '../styles/Header.css'; // Make sure to create a CSS file for styling

function Header() {

    // Add your profile URLs here
    const twitterURL = 'https://twitter.com/seidtweets';
    const linkedInURL = 'https://www.linkedin.com/in/jamesin-seidel-5325b147/';
    const substackURL = 'https://jamesin.substack.com/';
    const marketMapUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSckbOkhxswWYcxmpWp6rXFs5shMP9GMCqDHmTdqw2mYg9Rj5w/viewform?usp=sf_link';
    const contactFormURL = "https://docs.google.com/forms/d/e/1FAIpQLSfwp-3KHrQHbNS2DPYiG5sJI1ffL9Qn_4eLewwRDpTizmJ0Bw/viewform?usp=sf_link"
    const fundWebsiteURL = 'https://chapterone.com/';
    const ventureMapsSubstackURL = 'https://substack.com/@marketmaps';

    return (
        <header className="header">
            <div className="container">
                <h1 className="header-title">Venture Market Maps</h1>
                <div className="curated-investment-wrapper">
                    <div className="curated-by">
                        <p>CURATED BY: <a href={linkedInURL} target="_blank" rel="noopener noreferrer" className="contribution-links">JAMESIN SEIDEL</a> • EARLY-STAGE INVESTOR @ 
                            <a href={fundWebsiteURL} target="_blank" rel="noopener noreferrer" className="contribution-links"> CHAPTER ONE</a></p>
                    </div>
                    <div className="social-links">
                        <a href={twitterURL} target="_blank" rel="noopener noreferrer">TWITTER</a>•
                        <a href={linkedInURL} target="_blank" rel="noopener noreferrer"> LINKEDIN</a>•
                        <a href={substackURL} target="_blank" rel="noopener noreferrer"> SUBSTACK</a>
                    </div>
                    <div className="contribution-links">
                        <p>Suggest a venture market map to add: <a href={marketMapUrl} target="_blank" rel="noopener noreferrer"> HERE</a></p>
                    </div>      
                    <div className="contribution-links">
                        <p>Are you a founder or investor and want to connect? <a href={contactFormURL} target="_blank" rel="noopener noreferrer"> HERE</a></p>
                    </div>                                    
                    <div className="contribution-links">
                        <p>For real-time notifications of maps added: <a href={ventureMapsSubstackURL} target="_blank" rel="noopener noreferrer">SUBSCRIBE TO VENTURE MAPS SUBSTACK</a></p>
                    </div>                      
                </div>
            </div>
        </header>
    );
}

export default Header;
