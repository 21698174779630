// src/App.js
import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './components/Header'; // Make sure the path is correct based on your file structure
import ArticleList from './components/ArticleList'; // Ensure the path is correct

function App() {
  return (   
    <div className="App">
      <Header />
      <ArticleList />
    </div>
  );
}

export default App;