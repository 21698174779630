// ArticlesList.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient'; // Adjust the path as needed
import '../styles/ArticleList.css'; // Make sure this points to your CSS file

const Article = ({ article }) => (  
  <div className="article">
    <h2 className="title"><a href={article.websiteUrl} target="_blank" rel="noopener noreferrer">{article.title}</a></h2>
    <p className="date-author">{article.date} • {article.authors}</p>
    <p><a href={article.fundLink} target="_blank" rel="noopener noreferrer" className="fund-name">{article.fundName}</a></p>
    <a href={article.websiteUrl} target="_blank" rel="noopener noreferrer"><img src={article.imageUrl} alt={article.title} className="article-image"></img></a>
  </div>
);

const ArticlesList = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const { data, error } = await supabase
          .from('venture_market_maps') // Replace with your Supabase table name
          .select('*')
          .order("date", { ascending: false });

        if (error) throw error;

        setArticles(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="article-container">
      {articles.map((article, index) => (
        <Article key={index} article={article} />
      ))}
    </div>
  );  
};

export default ArticlesList;
